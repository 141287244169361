:root {
  --green: rgb(35,82,96);
  --shadow: 0 0 2px rgba(0, 0, 0, 0.05);
  --radius:6px;
}
body {
  background-color: #f3f2f7;
  scroll-behavior: smooth;
  overflow-x: hidden;
  width: 100vw;
}
#wrapper {
  height: 100%;
}
#cover {
}
.cover {
  background-color: black;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
}
.profile {
  width: 70px;
  height: 70px;
  position: absolute;
  background: var(--green);
  bottom: -35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 30px;
  border: 2px solid white;
  color: white;
}

#personalInfo {
  margin-top: 50px;
}
#personalInfo div {
  text-align: center;
}
#personalInfo div:nth-of-type(1) {
  font-size: 32px;
  font-weight: bold;
}
#personalInfo div:nth-of-type(2) {
  color: var(--green);
  font-size: 14px;
  margin-top: 10px;
}
#personalInfo div:nth-of-type(3) {
  font-size: 12px;
}
#personalInfo div:nth-of-type(4) {
  font-size: 12px;
  margin-top: 15px;
  padding: 0px 20px;
  opacity: 0.5;
}
#functions {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  gap: 20px;
}
.button {
  width: 100%;
  height: 70px;
  border: 1px solid #efefef;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  color: var(--green);
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  padding: 5px 0px;
  font-size: 14px;
  cursor: pointer;
}
.button.active {
  background-color: var(--green);
  color: #fff;
}
.button.active svg {
  fill: white;
}

#contact {
  background-color: white;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  margin: 10px;
  margin-top: 30px;
}
.contactItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 5px;
  border-top: 1px solid rgb(225, 225, 225);
  align-items: center;
}
.contactItemKey {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.contactItemValue{margin-right: 10px;}
.contactItem img {
  width: 16px;
  height: 16px;
  opacity: 0.74;
  margin: 0 10px;
}
.contactItem:nth-child(1) {
  border-top: none;
}
#social {
  margin: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  gap: 20px;
}

.saveButton{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--green);
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  color: #fff;
  padding: 20px;
  font-size: 20px;
  margin: 10px;
  cursor: pointer;
}